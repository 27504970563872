import { Link } from 'react-router-dom';

function Hero() {
  return (
    <div className="bg-[#f5f2eb] py-20 px-4">
      <div className="container mx-auto max-w-6xl flex flex-col lg:flex-row items-center justify-between gap-12">
        {/* Conteúdo do Lado Esquerdo */}
        <div className="flex-1">
          <h1 className="text-4xl lg:text-5xl font-bold text-gray-900 mb-6">
            Olá, eu sou Catarina,
            <br />
            <span className="text-3xl lg:text-4xl text-gray-700">
              Jornalista & Mestranda em Comunicação
            </span>
          </h1>
          
          <p className="text-lg text-gray-600 mb-8 leading-relaxed">
          Jornalista com formação em Comunicação Social e habilitação em Jornalismo pela Faculdade de Comunicação da Universidade Federal da Bahia, mestranda em Ciências da Comunicação pela Escola de Comunicações e Artes da Universidade de São Paulo. Possuo passagem pela maior agência de comunicação do mundo (Edelman) como Analista de Comunicação Plena, assim como experiência em assessoria de imprensa, social media, marketing e jornalismo multiplataforma (online, impresso, rádio e televisão). 
          </p>

          <Link 
            to="/cv"
            className="inline-flex items-center px-6 py-3 bg-[#b4d3b2] hover:bg-[#95c092] 
              text-white font-medium rounded-lg transition-colors duration-200"
          >
            <span>Veja meu Currículo</span>
            <svg 
              className="w-5 h-5 ml-2" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </Link>
        </div>

        {/* Imagem do Lado Direito */}
        <div className="flex-1 flex justify-center lg:justify-end">
          <div className="w-72 h-72 lg:w-96 lg:h-96 rounded-full overflow-hidden bg-gray-100 shadow-xl">
            <img
              src="/images/hero-image.jpg"
              alt="Catarina Lopes"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero; 