import { Container } from 'react-bootstrap';

function ResumeReel() {
  return (
    <Container fluid className="py-12 px-0">
      <div className="max-w-[1400px] mx-auto px-4 lg:px-16">
        <div className="text-center mb-12">
          <h1 className="text-3xl lg:text-4xl font-bold text-gray-900 mb-4">
            Resume Reel
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Confira uma seleção dos meus principais trabalhos como jornalista bilíngue
          </p>
        </div>
        
        {/* Container Responsivo do Vídeo */}
        <div className="max-w-4xl mx-auto">
          <div className="relative w-full overflow-hidden pt-[56.25%] rounded-lg shadow-lg">
            <iframe
              className="absolute top-0 left-0 w-full h-full"
              src="https://www.youtube.com/embed/2uq8Jm2dAvQ?autoplay=1&mute=1&playlist=2uq8Jm2dAvQ&loop=1&controls=1&modestbranding=1&rel=0&showinfo=0&fs=0&iv_load_policy=3&disablekb=1"
              title="Resume Reel"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ResumeReel; 