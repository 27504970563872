import { Container } from 'react-bootstrap';
import { useEffect } from 'react';

function Lattes() {
  useEffect(() => {
    window.open('https://lattes.cnpq.br/0432350411669835', '_blank');
  }, []);

  return (
    <Container fluid className="py-12 px-0 bg-[#f5f2eb]">
      <div className="max-w-[1400px] mx-auto px-4 lg:px-16">
        <div className="text-center mb-12">
          <h1 className="text-3xl lg:text-4xl font-bold text-gray-900 mb-4">
            Currículo Lattes
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Acesse meu currículo completo na Plataforma Lattes
          </p>
        </div>

        <div className="max-w-3xl mx-auto">
          <div className="bg-white rounded-lg shadow-lg p-8 text-center">
            <div className="mb-8">
              <svg 
                className="w-16 h-16 mx-auto mb-4 text-[#b4d3b2]" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
              <p className="text-gray-600 mb-6">
                O Currículo Lattes é um padrão nacional de registro das atividades acadêmicas e profissionais. 
                Clique no botão abaixo para acessar meu currículo completo na Plataforma Lattes.
              </p>
            </div>

            <a 
              href="https://lattes.cnpq.br/0432350411669835" 
              className="inline-flex items-center px-6 py-3 bg-[#b4d3b2] text-white rounded-lg hover:bg-[#95c092] transition-colors"
            >
              Acessar Currículo Lattes
              <svg 
                className="w-5 h-5 ml-2" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                />
              </svg>
            </a>

            <div className="mt-8 pt-6 border-t border-gray-200">
              <p className="text-sm text-gray-500">
                Última atualização: Janeiro de 2024
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Lattes; 