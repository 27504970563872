import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function NavbarComponent() {
  const [isOpen, setIsOpen] = useState(false);
  const [isPortfolioOpen, setIsPortfolioOpen] = useState(false);

  return (
    <nav className="bg-white border-b">
      <Container className="py-3">
        <div className="flex items-center justify-between px-4">
          {/* Logo/Nome */}
          <Link to="/" className="text-xl font-bold text-gray-800">
            Catarina Lopes
          </Link>

          {/* Links de Navegação */}
          <div className="hidden lg:flex items-center space-x-8">
            <Link to="/" className="text-gray-600 hover:text-gray-800 transition-colors">
              Início
            </Link>
            
            {/* Dropdown Portfólio - Desktop */}
            <div className="relative group">
              <button className="text-gray-600 hover:text-gray-800 transition-colors py-2">
                Portfólio
              </button>
              
              {/* Submenu Desktop */}
              <div className="absolute left-0 top-full w-48 bg-white invisible group-hover:visible opacity-0 group-hover:opacity-100 transform group-hover:translate-y-0 translate-y-1 transition-all duration-200 py-2 shadow-lg rounded-lg">
                <div className="absolute top-0 left-0 w-full h-1 bg-transparent -translate-y-1"></div>
                <Link 
                  to="/texto" 
                  className="block px-4 py-2 text-gray-600 hover:text-gray-800 hover:bg-gray-50"
                >
                  Textos
                </Link>
                <Link 
                  to="/insercoes" 
                  className="block px-4 py-2 text-gray-600 hover:text-gray-800 hover:bg-gray-50"
                >
                  Inserções na Mídia
                </Link>
                <Link 
                  to="/reel" 
                  className="block px-4 py-2 text-gray-600 hover:text-gray-800 hover:bg-gray-50"
                >
                  Resume Reel
                </Link>
              </div>
            </div>

            <Link to="/cv" className="text-gray-600 hover:text-gray-800 transition-colors">
              CV
            </Link>
            <Link to="/lattes" className="text-gray-600 hover:text-gray-800 transition-colors">
              Lattes
            </Link>
          </div>

          {/* Botão Mobile */}
          <button 
            className="lg:hidden p-2" 
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg 
              className="w-6 h-6" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>

        {/* Menu Mobile */}
        {isOpen && (
          <div className="lg:hidden mt-4 px-4 pb-4">
            <Link 
              to="/" 
              className="block py-2 text-gray-600 hover:text-gray-800"
              onClick={() => setIsOpen(false)}
            >
              Início
            </Link>
            
            {/* Portfólio Mobile com Toggle */}
            <div className="py-2">
              <button
                className="flex items-center justify-between w-full text-gray-600 hover:text-gray-800"
                onClick={() => setIsPortfolioOpen(!isPortfolioOpen)}
              >
                <span className="font-medium">Portfólio</span>
                <svg
                  className={`w-4 h-4 transition-transform duration-200 ${
                    isPortfolioOpen ? 'transform rotate-180' : ''
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              
              {/* Submenu Mobile */}
              {isPortfolioOpen && (
                <div className="pl-4 mt-2 space-y-2">
                  <Link 
                    to="/texto" 
                    className="block text-gray-600 hover:text-gray-800 py-1"
                    onClick={() => {
                      setIsPortfolioOpen(false);
                      setIsOpen(false);
                    }}
                  >
                    Textos
                  </Link>
                  <Link 
                    to="/insercoes" 
                    className="block text-gray-600 hover:text-gray-800 py-1"
                    onClick={() => {
                      setIsPortfolioOpen(false);
                      setIsOpen(false);
                    }}
                  >
                    Inserções na Mídia
                  </Link>
                  <Link 
                    to="/reel" 
                    className="block text-gray-600 hover:text-gray-800 py-1"
                    onClick={() => {
                      setIsPortfolioOpen(false);
                      setIsOpen(false);
                    }}
                  >
                    Resume Reel
                  </Link>
                </div>
              )}
            </div>

            <Link 
              to="/cv" 
              className="block py-2 text-gray-600 hover:text-gray-800"
              onClick={() => setIsOpen(false)}
            >
              CV
            </Link>
            <Link 
              to="/lattes" 
              className="block py-2 text-gray-600 hover:text-gray-800"
              onClick={() => setIsOpen(false)}
            >
              Lattes
            </Link>
          </div>
        )}
      </Container>
    </nav>
  );
}

export default NavbarComponent; 